.catinn-logo {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./svgs/logo.svg");
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 2;
  padding-top: 10px;
}

.dropdown-content .rounded {
  background: linear-gradient(to bottom, rgba(255,255,245,1), rgba(254,225,198,1));
}

.dropdown-content a {
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
