@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  /* font-family: CormorantInfant; */
  /* src: url('./fonts/CormorantInfant-Medium.ttf'); */
}

@layer base {
  html {
    /* font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif; */
    font-family: ui-serif, serif;
    /* font-family: CormorantInfant, ui-serif, serif; */
  }
}

.main-bgcolor {
  background: rgb(255, 247, 238);
}

.bg-paw {
  background-image: url("./svgs/paw.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.bg-cat {
  background-image: url("./svgs/cat.svg");
  /* background-size: 24px 24px; */
  background-repeat: no-repeat;
  background-position: right 30px top 30px;
}

.bg-onboard {
  background-image: url("./svgs/cat-onboard-3.svg");
  background-repeat: no-repeat;
  background-position: top 100px center;
  background-size: 60%;
}

.bg-onboard-form {
  background-image: url("./svgs/cat-onboard-4.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 100px center;
}

html {
  scroll-behavior: smooth;
}

body {
  background: rgb(255, 247, 238);
}
